<template>
    <div class="app-content app-container">
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                @submit.native.prevent
            >
                <el-form-item label="问卷ID" prop="sid">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.sid"
                        placeholder="问卷ID"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>

                <el-form-item label="Uscc" prop="uscc">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.uscc"
                        placeholder="Uscc"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="id" label="#"> </el-table-column>
                <el-table-column prop="uscc" label="uscc"> </el-table-column>
                <el-table-column prop="sid" label="sid"> </el-table-column>
                <el-table-column prop="uid" label="uid"> </el-table-column>
                <el-table-column prop="browser" label="浏览信息">
                    <template slot-scope="scope"
                        >{{ scope.row.browser }}
                        {{ scope.row.browserVersion }}</template
                    >
                </el-table-column>
                <el-table-column prop="release" label="引擎信息">
                    <template slot-scope="scope"
                        >{{ scope.row.engine }}
                        {{ scope.row.engineVersion }}</template
                    >
                </el-table-column>
                <el-table-column prop="platform" label="平台信息">
                </el-table-column>
                <el-table-column prop="mobile" label="是否手机"
                    ><template slot-scope="scope">{{
                        scope.row.mobile | isYes
                    }}</template>
                </el-table-column>
                <el-table-column prop="postTime" label="填写时间">
                    <template slot-scope="scope">{{
                        scope.row.postTime | dayjs
                    }}</template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
        <!-- 弹窗 -->
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { userAgentListApi } from '@/api/survey.js'
export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            formInline: {},
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
        }
    },
    created() {
        if (sessionStorage.getItem('formInline')) {
            this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
        }
        this.getUserAgentListFun()
    },
    methods: {
        // 获取用户信息列表
        async getUserAgentListFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: pageSize ? pageSize : 10,
                draw: 1,
                orderStr: 'id desc',
            }
            var p = Object.assign(params, this.formInline)
            var res = await userAgentListApi(p)
            this.pageparm.total = res.recordsTotal
            this.tableData = res.data
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            this.getUserAgentListFun()
            // console.log('onSubmit', this.formInline);
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline = {
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.getUserAgentListFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            // this.formInline.page = parm.currentPage
            // this.formInline.limit = parm.pageSize
            this.getUserAgentListFun(parm.currentPage, parm.pageSize)
        },
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
</style>
